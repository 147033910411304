import React, { useCallback, useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box } from '@mui/material';
import Lottie from 'lottie-react';
import loadingAnimation from '../assets/loading.json'; // Adjust the path as needed
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { functions } from '../firebase'; // Ensure you have configured Firebase correctly
import { httpsCallable } from 'firebase/functions';

// Make sure to call `loadStripe` outside of a component’s render to avoid recreating the `Stripe` object on every render.
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_T); // Test key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_L); // Live key

const CheckoutModal = ({ open, handleClose, priceId, credits, userId }) => {
  const [clientSecret, setClientSecret] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchClientSecret = useCallback(async () => {
    // console.log('Fetching client secret with:', { priceId, credits, userId });
    setLoading(true);
    try {
      const createCheckoutSession = httpsCallable(functions, 'createCheckoutSessionWithCors');
      const { data } = await createCheckoutSession({ priceId, credits, userId });
      // console.log(data.client_secret);
      setClientSecret(data.client_secret);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [priceId, credits, userId]);

  useEffect(() => {
    if (open && priceId && credits && userId) {
      // console.log("Modal Opened", priceId, credits, userId);
      fetchClientSecret();
    } else if (!open) {
      setClientSecret('');
    }
  }, [open, fetchClientSecret, priceId, credits, userId]);

  const handleCloseModal = () => {
    setClientSecret(null);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      PaperProps={{
        sx: {
          borderRadius: 4,
          padding: 3,
          width: '70%',
          maxWidth: 600,
          height: '70%',
          maxHeight: 700
        },
      }}
    >
      <DialogTitle>
        <Typography sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '1.5rem' }}>
          Complete Your Purchase
        </Typography>
      </DialogTitle>
      <DialogContent>
        {(loading) ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Lottie
              animationData={loadingAnimation}
              style={{ height: 200, width: 300 }}
            />
          </Box>
        ) : null}
        {clientSecret? <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret }}>
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider> : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseModal} color="primary" variant="contained" sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '1rem' }}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CheckoutModal;
