import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Box, Avatar, Button, Typography, IconButton } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import logo from '../assets/logo.png';
import CreditIcon from '@mui/icons-material/MonetizationOn';
import PeopleIcon from '@mui/icons-material/People';
import FriendshipsModal from './FriendshipsModal';

const NavBar = () => {
  const { currentUser, getCredits } = useAuth();
  const navigate = useNavigate();
  
  const [creds, setCreds] = useState('0');
  const [friendsModalOpen, setFriendsModalOpen] = useState(false);
  const location = useLocation();
  const fetchCredits = async () => {
    if (currentUser) {
      const data = await getCredits(currentUser.email);
      setCreds(data);
    }
  };

  useEffect(() => {
    fetchCredits();
  }, [currentUser, location]);

  const handleAvatarClick = () => {
    if (currentUser) {
      navigate('/profile');
    } else {
      navigate('/login');
    }
  };

  const handleBuyCreditsClick = () => {
    navigate('/buy-credits');
  };

  const handleFriendsClick = () => {
    setFriendsModalOpen(true);
  };

  const handleFriendsModalClose = () => {
    setFriendsModalOpen(false);
  };

  // Dummy data for friends and friend requests
  const friends = [
    { email: 'friend1@example.com', name: 'Friend 1', level: 5, bio: 'Loves Math' },
    { email: 'friend2@example.com', name: 'Friend 2', level: 3, bio: 'Science Geek' },
  ];

  const friendRequests = [
    { email: 'request1@example.com', name: 'Request 1' },
    { email: 'request2@example.com', name: 'Request 2' },
  ];

  const handleSendRequest = (email) => {
    // Implement the functionality to send a friend request
    console.log('Send request to:', email);
  };

  const handleAcceptRequest = (email) => {
    // Implement the functionality to accept a friend request
    console.log('Accept request from:', email);
  };

  const handleRejectRequest = (email) => {
    // Implement the functionality to reject a friend request
    console.log('Reject request from:', email);
  };

  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: '#feb546', borderRadius: 2, padding: '0 16px' }}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              component="img"
              sx={{ height: 40, cursor: 'pointer', marginRight: 2, borderRadius: 2 }}
              alt="Brand Logo"
              src={logo}
            />
            <Typography variant="h4" sx={{ cursor: 'pointer', fontFamily: 'Concert One, sans-serif' }} color={'black'} onClick={() => navigate('/main')}>
              Educat
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 2 }}>
              <CreditIcon />
              <Typography variant="h6" sx={{ marginLeft: 1, fontFamily: 'Concert One, sans-serif' }}>
                Credits: {creds}
              </Typography>
            </Box>
            <Button color="inherit" onClick={handleBuyCreditsClick} sx={{ height: 40, cursor: 'pointer', marginRight: 2, borderRadius: 2, borderColor: 'black', borderWidth: 0, borderStyle: 'solid', backgroundColor: 'rgba(25,255,255, 0.3)' }}>
              <Typography variant="button" sx={{ fontWeight: 'bold', fontFamily: 'Concert One, sans-serif', color: 'black' }}>
                Buy More Credits
              </Typography>
            </Button>
            <IconButton color="inherit" onClick={handleFriendsClick} sx={{ marginRight: 2 }}>
              <PeopleIcon />
              <Typography variant="button" sx={{ fontFamily: 'Concert One, sans-serif', marginLeft: 1 }}>
                Friends
              </Typography>
            </IconButton>
            <Avatar
              alt="User Avatar"
              src={currentUser?.photoURL || '/static/images/avatar/placeholder.jpg'}
              sx={{ cursor: 'pointer', borderWidth: 2, borderColor: "black", borderStyle: 'solid' }}
              onClick={handleAvatarClick}
            />
          </Box>
        </Toolbar>
      </AppBar>
      <FriendshipsModal
        open={friendsModalOpen}
        handleClose={handleFriendsModalClose}
        friends={friends}
        friendRequests={friendRequests}
        handleSendRequest={handleSendRequest}
        handleAcceptRequest={handleAcceptRequest}
        handleRejectRequest={handleRejectRequest}
      />
    </>
  );
};

export default NavBar;