import React, { useEffect, useState } from 'react';
import CollectionItem from '../components/CollectionItem';
import { Box } from '@mui/material';
import { useCollections } from '../hooks/useCollections';
import { useAuth } from '../hooks/useAuth';
import FilterToolbar from '../components/FilterToolbar';

const Collections = () => {
  const { userCollections, getCollectionsByUserEmail } = useCollections();
  const { currentUser } = useAuth();

  const [filteredCollections, setFilteredCollections] = useState([]);
  const [subjectFilter, setSubjectFilter] = useState('All');
  const [levelFilter, setLevelFilter] = useState('All');
  const [typeFilter, setTypeFilter] = useState('All');

  useEffect(() => {
    if (currentUser) {
      getCollectionsByUserEmail(currentUser.email);
    }
  }, [currentUser]);

  useEffect(() => {
    setFilteredCollections(
      userCollections.filter((collection) => {
        return (
          (subjectFilter === 'All' || collection.subject === subjectFilter) &&
          (levelFilter === 'All' || collection.level === levelFilter) &&
          (typeFilter === 'All' || collection.type === typeFilter)
        );
      })
    );
  }, [subjectFilter, levelFilter, typeFilter, userCollections]);

  const handleClearFilters = () => {
    setSubjectFilter('All');
    setLevelFilter('All');
    setTypeFilter('All');
  };

  return (
    <Box sx={{ paddingBottom: 20 }}>
      
      <FilterToolbar 
        subject={subjectFilter}
        setSubject={setSubjectFilter}
        level={levelFilter}
        setLevel={setLevelFilter}
        type={typeFilter}
        setType={setTypeFilter}
        handleClearFilters={handleClearFilters}
      />
      {filteredCollections.map((item) => (
        <CollectionItem
          key={item.id}
          id={item.id}
          name={item.name}
          progress={item.completeness}
          latestScore={item.latest_score}
          highestScore={item.highest_score}
          level={item.level}
          type={item.type}
          subject={item.subject}
        />
      ))}
    </Box>
  );
};

export default Collections;
