import React, { useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import { Button, TextField, Box, Container, Typography, IconButton, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Lottie from 'lottie-react';
import loadingAnimation from '../assets/loading.json'; // Adjust the path as needed

const SignUpPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { signup } = useAuth();
  const navigate = useNavigate();

  const handleSignUp = async () => {
    if (password !== confirmPassword) {
      alert('Passwords do not match');
      return;
    }
    setLoading(true);
    try {
      await signup(email, password, name);
      navigate('/main');
    } catch (error) {
      alert(error.message);
    }
    setLoading(false);
  };

  return (
    <Container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        fontFamily: 'Concert One, sans-serif',
      }}
    >
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Lottie
            animationData={loadingAnimation}
            style={{ height: 300, width: 300 }}
          />
        </Box>
      ) : (
        <Paper
          sx={{
            padding: 4,
            maxWidth: 400,
            width: '100%',
            position: 'relative',
            textAlign: 'center',
            borderRadius: '20px',
            bgcolor: 'rgba(255, 255, 255, 0.9)',
          }}
        >
          <IconButton
            sx={{ position: 'absolute', top: 8, left: 8 }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h5" gutterBottom sx={{ fontFamily: 'Concert One, sans-serif' }}>
            Sign Up
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={(e) => setName(e.target.value)}
              sx={{ borderRadius: '10px' }}
              disabled={loading}
            />
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={(e) => setEmail(e.target.value)}
              sx={{ borderRadius: '10px' }}
              disabled={loading}
            />
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={(e) => setPassword(e.target.value)}
              sx={{ borderRadius: '10px' }}
              disabled={loading}
            />
            <TextField
              label="Confirm Password"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={(e) => setConfirmPassword(e.target.value)}
              sx={{ borderRadius: '10px' }}
              disabled={loading}
            />
            <Button
              variant="contained"
              sx={{ mt: 2, borderRadius: '20px', fontFamily: 'Concert One, sans-serif' }}
              onClick={handleSignUp}
              disabled={loading}
            >
              Sign Up
            </Button>
          </Box>
          <Typography variant="body2" sx={{ mt: 2, fontFamily: 'Concert One, sans-serif' }}>
            Already a user?{' '}
            <Button
              variant="text"
              onClick={() => navigate('/login')}
              sx={{ fontFamily: 'Concert One, sans-serif' }}
              disabled={loading}
            >
              Sign in here
            </Button>
          </Typography>
        </Paper>
      )}
    </Container>
  );
};

export default SignUpPage;
