// src/pages/Roadmap.js
import React from 'react';
import { Box, Typography } from '@mui/material';

const Roadmap = () => {
  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h4">Roadmap</Typography>
      {/* Add roadmap content here */}
    </Box>
  );
};

export default Roadmap;