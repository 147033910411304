import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box, Typography } from '@mui/material';

const ForgotPasswordDialog = ({ open, onClose, onSubmit }) => {
  const [email, setEmail] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleForgotPassword = () => {
    onSubmit(email);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: 4,
          padding: 3,
          width: '100%',
          maxWidth: 400,
          fontFamily: 'Concert One, sans-serif',
        },
      }}
    >
      <DialogTitle>
        <Typography sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '1.5rem' }}>
          Reset Password
        </Typography>
      </DialogTitle>
      <DialogContent>
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={handleEmailChange}
          sx={{ borderRadius: '10px' }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '1rem' }}>
          Cancel
        </Button>
        <Button onClick={handleForgotPassword} color="primary" variant="contained" sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '1rem' }}>
          Send Reset Email
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ForgotPasswordDialog;
