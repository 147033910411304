// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import LoginPage from './pages/LoginPage';
import SignUpPage from './pages/SignUpPage';
import MainPage from './pages/MainPage';
import CollectionPractice from './pages/CollectionPractice';
import Roadmap from './pages/Roadmap';
import UserProfile from './pages/UserProfile';
import BuyCredits from './pages/BuyCredits';
import Return from './pages/Return';
import { AuthProvider } from './hooks/useAuth';
import { FriendshipsProvider } from './hooks/useFriendships';

function App() {
  return (
    <AuthProvider>
      <FriendshipsProvider>
        <Router>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/main" element={<MainPage />} />
            <Route path="/roadmap" element={<Roadmap />} />
            <Route path="/collection-practice/:collectionId" element={<CollectionPractice />} />
            <Route path="/buy-credits" element={<BuyCredits />} />
            <Route path="/profile" element={<UserProfile />} />
            <Route path="/return" element={<Return />} />
          </Routes>
        </Router>
      </FriendshipsProvider>
    </AuthProvider>
  );
}

export default App;
