import React, { useEffect, useState } from 'react';
import { Box, Container, IconButton, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ProfileHeader from '../components/ProfileHeader';
import Statistics from '../components/Statistics';
import Achievements from '../components/Achievements';
import CurrentSkills from '../components/CurrentSkills';
import LogoutIcon from '@mui/icons-material/Logout';
import FeedbackModal from '../components/FeedbackModal';

const UserProfile = () => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const [skillsData, setSkillsData] = useState([]);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  
  useEffect(() => {
    if (currentUser && currentUser.skills) {
      const formattedSkills = Object.keys(currentUser.skills).map(skill => ({
        skill,
        level: currentUser.skills[skill],
      }));
      setSkillsData(formattedSkills);
    }
  }, [currentUser]);

  const handleLogout = async () => {
    await logout();
    navigate('/');
  };

  
  const handleFeedbackClick = () => {
    setFeedbackOpen(true);
  };

  const handleFeedbackClose = () => {
    setFeedbackOpen(false);
  };

  return (
    <Container sx={{ textAlign: 'center', mt: 5, fontFamily: 'Concert One, sans-serif' }}>
      <IconButton
        sx={{ position: 'absolute', top: 16, left: 16 }}
        onClick={() => navigate(-1)}
      >
        <ArrowBackIcon />
      </IconButton>
      <Box sx={{height: 40, position: 'absolute', top: 16, right: 130, }}>
        <Button color="inherit" onClick={handleFeedbackClick} sx={{ cursor: 'pointer', marginRight: 2, borderRadius: 2, backgroundColor: 'rgba(25,255,255, 0.7)' }}>
          <Typography variant="button" sx={{ fontWeight: 'bold', fontFamily: 'Concert One, sans-serif', color: 'black' }}>
            Feedback
          </Typography>
        </Button>
        <Button
          sx={{ color: 'white', backgroundColor: 'lightcoral', '&:hover': { backgroundColor: 'lightcoral' } }}
          onClick={handleLogout}
          startIcon={<LogoutIcon />}
        >
          <Typography variant="button" sx={{ fontWeight: 'bold', fontFamily: 'Concert One, sans-serif', color: 'black',borderRadius: 2 }}>
            Logout
          </Typography>
        </Button>
      </Box>
      
      
      <Box>
        <Typography variant="h1" sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '3.5rem', mb: 6 }}>
          My Profile
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mb: 3 }}>
          <ProfileHeader />
          <Statistics statistics={null} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mb: 3 }}>
          <Achievements />
          <CurrentSkills skillsData={skillsData} />
        </Box>
      </Box>
      <FeedbackModal open={feedbackOpen} onClose={handleFeedbackClose} />
    </Container>
  );
};

export default UserProfile;
