import React from 'react';
import { Box, Typography, Button, Avatar, Paper } from '@mui/material';

const FriendRequestItem = ({ request, onAccept, onReject }) => {
  return (
    <Paper sx={{ p: 2, display: 'flex', alignItems: 'center', mb: 2, borderRadius: '12px', fontFamily: 'Concert One, sans-serif' }}>
      <Avatar src={request.photoURL} alt={request.name} sx={{ width: 48, height: 48, mr: 2 }} />
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="body1" sx={{ fontFamily: 'Concert One, sans-serif' }}>
          {request.name}
        </Typography>
      </Box>
      <Box>
        <Button variant="contained" color="primary" onClick={onAccept} sx={{ mr: 1, fontFamily: 'Concert One, sans-serif' }}>
          Accept
        </Button>
        <Button variant="contained" color="error" onClick={onReject} sx={{ fontFamily: 'Concert One, sans-serif' }}>
          Reject
        </Button>
      </Box>
    </Paper>
  );
};

export default FriendRequestItem;
