import React, { useState } from 'react';
import { Box, Typography, LinearProgress, Paper, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CollectionItem = ({ id, name, progress, latestScore, highestScore, level, type, subject }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleProceed = () => {
    setOpen(false);
    navigate(`/collection-practice/${id}`);
  };

  return (
    <Paper elevation={3} sx={{ mb: 3, p: 3, borderRadius: '20px', backgroundColor: '#f9f6f1', fontFamily: 'Concert One, sans-serif' }}>
      <Typography variant="h5" sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '1.5rem' }}>
        {name}
      </Typography>
      <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
        <Typography
          variant="body2"
          sx={{
            backgroundColor: '#e0f7fa',
            borderRadius: '10px',
            px: 1.5,
            py: 0.5,
            fontFamily: 'Concert One, sans-serif',
            fontSize: '0.9rem',
            color: '#00796b'
          }}
        >
          {level}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            backgroundColor: '#ffecb3',
            borderRadius: '10px',
            px: 1.5,
            py: 0.5,
            fontFamily: 'Concert One, sans-serif',
            fontSize: '0.9rem',
            color: '#ff6f00'
          }}
        >
          {type}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            backgroundColor: '#ffcdd2',
            borderRadius: '10px',
            px: 1.5,
            py: 0.5,
            fontFamily: 'Concert One, sans-serif',
            fontSize: '0.9rem',
            color: '#d32f2f'
          }}
        >
          {subject}
        </Typography>
      </Box>
      <LinearProgress variant="determinate" value={progress} sx={{ height: '12px', borderRadius: '6px', mt: 2 }} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
        <Box>
          <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '1rem' }}>
            {progress}% Complete
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1, fontFamily: 'Concert One, sans-serif', fontSize: '1rem' }}>
            Latest Score: {latestScore}
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1, fontFamily: 'Concert One, sans-serif', fontSize: '1rem' }}>
            Highest Score: {highestScore}
          </Typography>
        </Box>
        <Button variant="contained" sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '1rem', borderRadius: '15px' }} onClick={handleClickOpen}>
          Play
        </Button>
      </Box>

      <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { borderRadius: '20px', padding: 3 } }}>
        <DialogTitle sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '1.5rem' }}>Start Collection</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '1.2rem' }}>
            Do you want to proceed with the collection?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '1rem' }}>
            Cancel
          </Button>
          <Button onClick={handleProceed} color="primary" autoFocus sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '1rem' }}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default CollectionItem;
