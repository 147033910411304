import React from 'react';
import { Box, Typography } from '@mui/material';

const Achievements = ({ achievements }) => {
  return (
    <Box sx={{ p: 3, bgcolor: '#e0e0e0', borderRadius: '25px', minWidth: '45%', opacity: 0.6, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mr: 3 }}>
      <Typography variant="h5" sx={{ fontFamily: 'Concert One, sans-serif', mb: 2 }}>
        Achievements
      </Typography>
      <Typography variant="body1" sx={{ fontFamily: 'Concert One, sans-serif', color: '#757575' }}>
        Coming Soon
      </Typography>
    </Box>
  );
};

export default Achievements;
