import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, Container, CircularProgress, Button, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useCollections } from '../hooks/useCollections';
import QuestionCard from '../components/QuestionCard';
import AnswerCard from '../components/AnswerCard';
import { useAuth } from '../hooks/useAuth';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import { getLevelFromXP, getXPForNextLevel } from '../utils/ExperiencePoints';
import CustomDialog from '../components/CustomDialog';

const CollectionPractice = () => {
  const { collectionId } = useParams();
  const { getQuestionsForCollection, questions, updateCollectionCompleteness } = useCollections();
  const [answers, setAnswers] = useState({});
  const [showAnswers, setShowAnswers] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [score, setScore] = useState(null);
  const [earnedExperiencePoints, setEarnedExperiencePoints] = useState(null);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchQuestions = async () => {
      await getQuestionsForCollection(collectionId);
      setLoading(false);
    };
    fetchQuestions();
  }, []);

  const handleAnswerChange = (questionId, answer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: answer,
    }));
  };

  const calculateExperiencePoints = (correctAnswers, totalQuestions, collectionExperiencePoints) => {
    const scorePercentage = (correctAnswers / totalQuestions) * 100;
    const earnedExperiencePoints = Math.round((scorePercentage / 100) * collectionExperiencePoints);
    return earnedExperiencePoints;
  };

  const handleSubmit = async () => {
    const allAnswered = questions.every((q) => answers[q.id] !== undefined);
    if (!allAnswered) {
      alert('Please answer all questions before submitting.');
      return;
    }

    let correctAnswers = 0;
    questions.forEach((question) => {
      if (answers[question.id] === question.answer) {
        correctAnswers += 1;
      }
    });

    const latestScore = `${correctAnswers}/${questions.length}`;
    const countCorrect = correctAnswers;
    setScore(latestScore);
    setShowAnswers(true);
    const collectionDoc = await getDoc(doc(db, 'question_collections', collectionId));
    if (collectionDoc.exists()) {
      const collectionExperiencePoints = collectionDoc.data().experience_points;
      const earnedExperiencePoints = calculateExperiencePoints(correctAnswers, questions.length, collectionExperiencePoints);
      setEarnedExperiencePoints(earnedExperiencePoints);

      updateCollectionCompleteness(collectionId, currentUser.email, questions.length, countCorrect, latestScore, earnedExperiencePoints);
    }
  };

  const handleBackButtonClick = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleConfirmLeave = () => {
    navigate(-1);
  };

  const handleGoHome = () => {
    navigate('/main');
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (questions.length === 0) {
    return <Typography variant="h6">No questions found for this collection</Typography>;
  }

  return (
    <Container sx={{ mt: 5, fontFamily: 'Concert One, sans-serif' }}>
      {!showAnswers && (
        <IconButton
          sx={{ position: 'absolute', top: 16, left: 16 }}
          onClick={handleBackButtonClick}
        >
          <ArrowBackIcon />
        </IconButton>
      )}
      <Box sx={{ mt: 3 }}>
        <Typography variant="h4" sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '2rem', mb: '2rem' }}>
          Collection Practice
        </Typography>
        {questions.map((question) => (
          <Box key={question.id} sx={{ mb: 3 }}>
            <QuestionCard
              question={question}
              onChange={(answer) => handleAnswerChange(question.id, answer)}
              selectedAnswer={answers[question.id]}
              showAnswer={showAnswers}
            />
            {showAnswers && (
              <AnswerCard
                answer={question.answer}
                explanation={question.explanation}
                correct={answers[question.id] === question.answer}
              />
            )}
          </Box>
        ))}
        {!showAnswers ? (
          <Button variant="contained" sx={{ mt: 3, fontFamily: 'Concert One, sans-serif', fontSize: '1.2rem' }} onClick={handleSubmit}>
            Submit
          </Button>
        ) : (
          <Container sx={{ mt: 3, textAlign: 'center' }}>
            <Typography variant="h6" sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '1.5rem' }}>
              Score: {score}
            </Typography>
            <Button variant="contained" sx={{ mt: 3, fontFamily: 'Concert One, sans-serif', fontSize: '1.2rem' }} onClick={handleGoHome}>
              Go back to home page
            </Button>
          </Container>
        )}
      </Box>
      <CustomDialog
        open={earnedExperiencePoints !== null}
        onClose={() => setEarnedExperiencePoints(null)}
        title="Congratulations!"
        content={[
          `You have gained ${earnedExperiencePoints} experience points.`,
          `Points to next level: ${getXPForNextLevel(currentUser.experience_points + earnedExperiencePoints)}`
        ]}
        onConfirm={() => setEarnedExperiencePoints(null)}
        confirmText="Close"
        cancelText=""
      />
      <CustomDialog
        open={openDialog}
        onClose={handleDialogClose}
        title="Confirm Leave? Progress will not be saved."
        content={[]}
        onConfirm={handleConfirmLeave}
        confirmText="Leave"
        cancelText="Cancel"
      />
    </Container>
  );
};

export default CollectionPractice;
