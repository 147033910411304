import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import emailjs from 'emailjs-com';

const FeedbackModal = ({ open, onClose }) => {
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [name, setName] = useState('');

  const handleSendFeedback = () => {
    const templateParams = {
      subject,
      message,
      name,
      email: process.env.REACT_APP_FEEDBACK_EMAIL, // Your email address from .env
    };

    console.log(templateParams);

    emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      templateParams,
      process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
      
    )
      .then(() => {
        alert('Feedback sent successfully!');
        onClose();
      })
      .catch((error) => {
        console.error('Failed to send feedback:', error);
        alert('Failed to send feedback. Please try again later.');
      });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: '20px',
          padding: 3,
          width: '80%',
          maxWidth: 600,
          fontFamily: 'Concert One, sans-serif',
        },
      }}
    >
      <DialogTitle sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '1.5rem' }}>
        Send Feedback
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <TextField
            label="Subject"
            variant="outlined"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '1rem' }}
          />
          <TextField
            label="Message"
            variant="outlined"
            multiline
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '1rem' }}
          />
          <TextField
            label="Name (optional)"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '1rem' }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
          sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '1rem' }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSendFeedback}
          color="primary"
          sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '1rem' }}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FeedbackModal;