import React, { useEffect, useState } from 'react';
import { Box, Typography, Container, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import loadingAnimation from '../assets/loading.json'; // Adjust the path as needed
import { functions } from '../firebase'; // Ensure you have configured Firebase correctly
import { httpsCallable } from 'firebase/functions';

const Return = () => {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');

    const fetchSessionStatus = async () => {
      try {
        const getSessionStatus = httpsCallable(functions, 'getSessionStatus');
        const { data } = await getSessionStatus({ sessionId });
        setStatus(data.status);
        setCustomerEmail(data.customer_email);
      } catch (error) {
        console.error('Error fetching session status:', error);
      }
    };

    fetchSessionStatus();
  }, []);

  useEffect(() => {
    if (status === 'open') {
      navigate('/buy-credits');
    }
  }, [status, navigate]);

  if (!status) {
    return (
      <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Lottie animationData={loadingAnimation} style={{ height: 200, width: 300 }} />
      </Container>
    );
  }

  if (status === 'complete') {
    return (
      <Container sx={{ textAlign: 'center', mt: 5 }}>
        <Typography variant="h4" sx={{ fontFamily: 'Concert One, sans-serif', mb: 3 }}>
          Thank You for Your Purchase!
        </Typography>
        <Typography variant="body1" sx={{ fontFamily: 'Concert One, sans-serif', mb: 2 }}>
          We appreciate your business! A confirmation email will be sent to {customerEmail}.
        </Typography>
        <Typography variant="body1" sx={{ fontFamily: 'Concert One, sans-serif', mb: 2 }}>
          If you have any questions, please email <a href="mailto:educat.quest@gmail.com">educat.quest@gmail.com</a>.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 3, fontFamily: 'Concert One, sans-serif', fontSize: '1rem' }}
          onClick={() => navigate('/main')}
        >
          Back to Home
        </Button>
      </Container>
    );
  }

  return null;
};

export default Return;