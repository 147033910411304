import React, { useEffect, useState } from 'react';
import { Modal, Box, Tab, Tabs, Typography, TextField, Button, Paper, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FriendItem from './FriendItem';
import FriendRequestItem from './FriendRequestItem';
import { useFriendships } from '../hooks/useFriendships';

const FriendshipsModal = ({ open, handleClose }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const { friends, friendRequests, sendFriendRequest, acceptFriendRequest, rejectFriendRequest } = useFriendships();
  const [email, setEmail] = useState('');

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleSendRequest = async () => {
    if (email) {
      try {
        await sendFriendRequest(email);
        alert("Sent friend request to: ", email);
        setEmail('');
      } catch (err) {
        console.log("Failed to send request",err);
        alert("Failed to send request to: ", email);
      }
      
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Paper sx={{ p: 4, borderRadius: '25px', maxWidth: '600px', margin: 'auto', mt: '5%', position: 'relative' }}>
        <IconButton
          onClick={handleClose}
          sx={{ position: 'absolute', top: 8, right: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h5" sx={{ mb: 2, fontFamily: 'Concert One, sans-serif' }}>Friendships</Typography>
        <Tabs value={tabIndex} onChange={handleTabChange} centered>
          <Tab label="Friends" sx={{ fontFamily: 'Concert One, sans-serif' }}/>
          <Tab label="Friend Requests" sx={{ fontFamily: 'Concert One, sans-serif' }}/>
        </Tabs>
        <Box sx={{ mt: 2 }}>
          {tabIndex === 0 && (
            <Box sx={{ borderRadius: '25px', p: 2, backgroundColor: '#f5f5f5', minHeight: '40vh' }}>
              {friends.length > 0 ? (
                friends.map(friend => <FriendItem key={friend.email} friend={friend} />)
              ) : (
                <Box sx={{ borderRadius: '25px', p: 2, backgroundColor: '#e0e0e0', textAlign: 'center' }}>
                  <Typography variant="body1" sx={{ fontFamily: 'Concert One, sans-serif' }}>Add some friends!</Typography>
                </Box>
              )}
            </Box>
          )}
          {tabIndex === 1 && (
            <Box sx={{ borderRadius: '25px', p: 2, backgroundColor: '#f5f5f5', minHeight: '40vh' }}>
              <Box sx={{ mb: 2 }}>
                <TextField
                  label="Friend's Email"
                  variant="outlined"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{ mb: 2, fontFamily: 'Concert One, sans-serif', borderRadius: '20px' }}
                />
                <Button variant="contained" fullWidth onClick={handleSendRequest} sx={{ fontFamily: 'Concert One, sans-serif' }}>
                  Send Friend Request
                </Button>
              </Box>
              <Box sx={{ borderRadius: '20px', p: 2, backgroundColor: '#e0e0e0', textAlign: 'center', minHeight: '20vh' }}>
                {friendRequests.length > 0 ? (
                  friendRequests.map(request => (
                    <FriendRequestItem
                      key={request.email}
                      request={request}
                      onAccept={() => acceptFriendRequest(request.requestId, request.email)}
                      onReject={() => rejectFriendRequest(request.requestId)}
                    />
                  ))
                ) : (
                  <Box sx={{ borderRadius: '20px', p: 2, backgroundColor: '#e0e0e0', textAlign: 'center' }}>
                    <Typography variant="body1" sx={{ fontFamily: 'Concert One, sans-serif' }}>No Friend Requests!</Typography>
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Paper>
    </Modal>
  );
};

export default FriendshipsModal;
