import React from 'react';
import { Box, Typography } from '@mui/material';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, ResponsiveContainer } from 'recharts';

const CurrentSkills = ({ skillsData }) => {
  return (
    <Box sx={{ p: 3, bgcolor: '#f5f5f5', borderRadius: '25px', minWidth: '45%', }}>
      <Typography variant="h5" sx={{ fontFamily: 'Concert One, sans-serif', mb: 2 }}>
        Skills
      </Typography>
      <ResponsiveContainer width="100%" height={400}>
        <RadarChart data={skillsData.length ? skillsData : [{ skill: 'No Skills', level: 0 }]}>
          <PolarGrid />
          <PolarAngleAxis dataKey="skill" />
          <Radar name="Skill Level" dataKey="level" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
        </RadarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default CurrentSkills;
