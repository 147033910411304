import React from 'react';
import { Button, MenuItem, Select, FormControl, InputLabel, Paper } from '@mui/material';

const FilterToolbar = ({ subject, setSubject, level, setLevel, type, setType, handleClearFilters }) => {
  return (
    <Paper sx={{ p: 3, mb: 3, borderRadius: '20px', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', backgroundColor: '#e5e1f1' }}>
      <FormControl variant="outlined" sx={{ minWidth: 140, fontFamily: 'Concert One, sans-serif' }}>
        <InputLabel sx={{ fontFamily: 'Concert One, sans-serif' }}>Subject</InputLabel>
        <Select
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          label="Subject"
          sx={{ borderRadius: '10px', fontFamily: 'Concert One, sans-serif' }}
        >
          <MenuItem value="All" sx={{ fontFamily: 'Concert One, sans-serif' }}>All</MenuItem>
          <MenuItem value="Math" sx={{ fontFamily: 'Concert One, sans-serif' }}>Math</MenuItem>
          <MenuItem value="Science" sx={{ fontFamily: 'Concert One, sans-serif' }}>Science</MenuItem>
          <MenuItem value="Languages" sx={{ fontFamily: 'Concert One, sans-serif' }}>Languages</MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="outlined" sx={{ minWidth: 140, fontFamily: 'Concert One, sans-serif' }}>
        <InputLabel sx={{ fontFamily: 'Concert One, sans-serif' }}>Level</InputLabel>
        <Select
          value={level}
          onChange={(e) => setLevel(e.target.value)}
          label="Level"
          sx={{ borderRadius: '10px', fontFamily: 'Concert One, sans-serif' }}
        >
          <MenuItem value="All" sx={{ fontFamily: 'Concert One, sans-serif' }}>All</MenuItem>
          {Array.from({ length: 6 }, (_, i) => (
            <MenuItem key={`Primary${i + 1}`} value={`Primary ${i + 1}`} sx={{ fontFamily: 'Concert One, sans-serif' }}>
              {`Primary ${i + 1}`}
            </MenuItem>
          ))}
          {Array.from({ length: 5 }, (_, i) => (
            <MenuItem key={`Secondary${i + 1}`} value={`Secondary ${i + 1}`} sx={{ fontFamily: 'Concert One, sans-serif' }}>
              {`Secondary ${i + 1}`}
            </MenuItem>
          ))}
          <MenuItem key={'O-levels'} value={'O-levels'} sx={{ fontFamily: 'Concert One, sans-serif' }}>
            O-levels
          </MenuItem>
          <MenuItem key={'N-levels'} value={'N-levels'} sx={{ fontFamily: 'Concert One, sans-serif' }}>
            N-levels
          </MenuItem>
          <MenuItem key={'A-levels'} value={'A-levels'} sx={{ fontFamily: 'Concert One, sans-serif' }}>
            A-levels
          </MenuItem>
          <MenuItem key={'Generic'} value={'Generic'} sx={{ fontFamily: 'Concert One, sans-serif' }}>
            Generic
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="outlined" sx={{ minWidth: 140, fontFamily: 'Concert One, sans-serif' }}>
        <InputLabel sx={{ fontFamily: 'Concert One, sans-serif' }}>Type</InputLabel>
        <Select
          value={type}
          onChange={(e) => setType(e.target.value)}
          label="Type"
          sx={{ borderRadius: '10px', fontFamily: 'Concert One, sans-serif' }}
        >
          <MenuItem value="All" sx={{ fontFamily: 'Concert One, sans-serif' }}>All</MenuItem>
          <MenuItem value="MCQ" sx={{ fontFamily: 'Concert One, sans-serif' }}>MCQ</MenuItem>
          <MenuItem value="Open-End" sx={{ fontFamily: 'Concert One, sans-serif' }}>Open-End</MenuItem>
          <MenuItem value="Mixed" sx={{ fontFamily: 'Concert One, sans-serif' }}>Mixed</MenuItem>
        </Select>
      </FormControl>
      <Button
        variant="contained"
        sx={{
          borderRadius: '15px',
          fontFamily: 'Concert One, sans-serif',
          bgcolor: 'rgba(254,181,70,1.0)',
        }}
        onClick={handleClearFilters}
      >
        Clear Filters
      </Button>
    </Paper>
  );
};

export default FilterToolbar;
