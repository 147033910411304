// src/components/FriendItem.js
import React, { useEffect, useState } from 'react';
import { Box, Typography, Avatar, Paper } from '@mui/material';
import { getLevelFromXP } from '../utils/ExperiencePoints';

const FriendItem = ({ friend }) => {
  // console.log(friend);
  if (!friend) return null;

  return (
    <Paper sx={{ p: 2, display: 'flex', alignItems: 'center', mb: 2, borderRadius: '20px', fontFamily: 'Concert One, sans-serif', borderWidth: 2, borderStyle: 'solid' }}>
      <Avatar alt={friend.name} src={friend.photoURL || '/static/images/avatar/1.jpg'} sx={{ width: '100px' , height: '100px', mr: 10, borderRadius: '20px', borderWidth: 1, borderStyle: 'solid' }} />
      <Box>
        <Typography variant="h6" sx={{ fontFamily: 'Concert One, sans-serif' }}>{friend.name}</Typography>
        <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Concert One, sans-serif' }}>
          Level: {getLevelFromXP(friend.experience_points)}
        </Typography>
        <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Concert One, sans-serif' }}>
          School Level: {friend.level}
        </Typography>
        <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Concert One, sans-serif' }}>
          {friend.bio || 'No status'}
        </Typography>
      </Box>
    </Paper>
  );
};

export default FriendItem;