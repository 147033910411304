import React, { useEffect, useState } from 'react';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ShopItem from '../components/ShopItem';
import ShopItemComingSoon from '../components/ShopItemComingSoon';
import { useCollections } from '../hooks/useCollections';
import { useAuth } from '../hooks/useAuth';
import { functions } from '../firebase'; // Ensure you have configured Firebase correctly
import { httpsCallable } from 'firebase/functions';
import FilterToolbar from '../components/FilterToolbar'; // Import the FilterToolbar component
import Lottie from 'lottie-react';
import loadingAnimation from '../assets/loading.json'; // Adjust the path as needed

const Shop = () => {
  const { collections, getAllCollections, isCollectionOwned } = useCollections();
  const { currentUser, refreshUser } = useAuth();
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [subject, setSubject] = useState('All');
  const [level, setLevel] = useState('All');
  const [type, setType] = useState('All');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getAllCollections();
  }, []);

  const handleBuy = (itemId) => {
    const item = collections.find(collection => collection.id === itemId);
    if (!item) return;

    if (currentUser.credits <= item.price) {
      setSelectedItem(item);
      setOpen(true);
      return;
    }

    setSelectedItem(item);
    setConfirmOpen(true);
  };

  const handleConfirmBuy = async () => {
    setLoading(true);
    try {
      const buyCollection = httpsCallable(functions, 'buyCollection');
      const response = await buyCollection({ collectionId: selectedItem.id, userId: currentUser.email });
      alert(response.data.message);
      await refreshUser();
    } catch (error) {
      console.error('Error buying collection:', error);
      alert(error.message);
    } finally {
      setLoading(false);
      setConfirmOpen(false);
      setSelectedItem(null);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setConfirmOpen(false);
    setSelectedItem(null);
  };

  const handleBuyCredits = () => {
    navigate('/buy-credits');
  };

  const handleClearFilters = () => {
    setSubject('All');
    setLevel('All');
    setType('All');
  };

  const filteredCollections = collections.filter(item => {
    return (subject === 'All' || item.subject === subject) &&
           (level === 'All' || item.level === level) &&
           (type === 'All' || item.type === type);
  });

  return (
    <Box sx={{ textAlign: 'center' }}>
      <FilterToolbar 
        subject={subject}
        setSubject={setSubject}
        level={level}
        setLevel={setLevel}
        type={type}
        setType={setType}
        handleClearFilters={handleClearFilters}
      />
      {filteredCollections.length === 0 ? (
        <ShopItemComingSoon />
      ) : (
        filteredCollections.map((item) => (
          <ShopItem 
            key={item.id} 
            item={item} 
            onBuy={handleBuy} 
            disabled={isCollectionOwned(item.id) || loading} // Disable if loading
          />
        ))
      )}
      <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { borderRadius: '20px', padding: 3 } }}>
        <DialogTitle sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '1.5rem' }}>Not Enough Credits</DialogTitle>
        <DialogContent>
          <Typography sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '1.2rem' }}>
            You don't have enough credits to buy {selectedItem?.name}. Would you like to buy more credits?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '1rem' }}>Cancel</Button>
          <Button onClick={handleBuyCredits} color="primary" sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '1rem' }}>Buy More Credits</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmOpen} onClose={handleClose} PaperProps={{ sx: { borderRadius: '20px', padding: 3 } }}>
        <DialogTitle sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '1.5rem' }}>Confirm Purchase</DialogTitle>
        <DialogContent>
          <Typography sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '1.2rem' }}>
            Are you sure you want to buy {selectedItem?.name} for {selectedItem?.price} credits?
          </Typography>
          {loading && (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', mt: 2 }}>
              <Lottie animationData={loadingAnimation} style={{ height: 100, width: 150 }} />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={loading} color="primary" sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '1rem' }}>Cancel</Button>
          <Button onClick={handleConfirmBuy} color="primary" sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '1rem' }} disabled={loading}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Shop;
