import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Box, Container, Paper, Typography } from '@mui/material';
import NavBar from '../components/NavBar';
import Collections from './Collections';
import Shop from './Shop';
import { useAuth } from '../hooks/useAuth';
import peekCat from '../assets/catpeek.png'

const MainPage = () => {
  const [value, setValue] = useState(0);
  const { currentUser } = useAuth();
  

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <NavBar />
      <Container sx={{ mt: 4, textAlign: 'center' }}>
        <Typography variant='h2' sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '3rem' }}>
          Welcome back, {currentUser?.name}.
        </Typography>
      </Container>
      
      <Container sx={{ mt: 0 }}>
        <Box
          sx={{
            position: 'relative',
            top: 25,
            left: 200,
            textAlign: 'center',
            maxWidth: '10%',
            maxHeight: '10%'
          }}
        >
          <img
            src={peekCat}
            alt="Cute animal peeking"
            style={{
              width: '80%',
            }}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
          <Paper elevation={3} sx={{ width: '80%', height: '70vh', borderRadius: '20px', overflow: 'hidden', backgroundColor: '#f9f6f1' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              centered
              sx={{ backgroundColor: '#b3cfff', color: 'white', fontFamily: 'Concert One, sans-serif', fontSize: '1.2rem' }}
            >
              <Tab label="My Collection" sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '1.2rem' }} />
              <Tab label="Shop" sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '1.2rem' }} />
            </Tabs>
            
            <Box sx={{ height: 'calc(100% - 48px)', overflowY: 'auto', p: 4 }}>
            
              {value === 0 && <Collections />}
              {value === 1 && <Shop />}
            </Box>
          </Paper>
        </Box>
      </Container>
    </div>
  );
};

export default MainPage;
