import React, { useState } from 'react';
import { Box, Typography, Paper, Button, Chip } from '@mui/material';
import ShopItemSampleModal from './ShopItemSampleModal';

const ShopItem = ({ item, onBuy, disabled }) => {
  const [sampleModalOpen, setSampleModalOpen] = useState(false);

  const handleOpenSampleModal = () => {
    setSampleModalOpen(true);
  };

  const handleCloseSampleModal = (event) => {
    event.stopPropagation();
    setSampleModalOpen(false);
  };

  return (
    <Paper
      elevation={3}
      sx={{
        mb: 3,
        p: 3,
        borderRadius: '20px',
        backgroundColor: disabled ? '#e0e0e0' : '#f9f6f1',
        fontFamily: 'Concert One, sans-serif',
      }}
      
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography
          variant="h5"
          sx={{
            fontFamily: 'Concert One, sans-serif',
            fontSize: '1.5rem',
          }}
        >
          {item.name}
        </Typography>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Chip label={item.level} color="primary" size="small" sx={{ borderRadius: 2, fontFamily: 'Concert One, sans-serif', }} />
          <Chip label={item.type} color="secondary" size="small" sx={{ borderRadius: 2, fontFamily: 'Concert One, sans-serif', }} />
          <Chip label={item.subject} color="success" size="small" sx={{ borderRadius: 2, fontFamily: 'Concert One, sans-serif', }} />
        </Box>
      </Box>
      <Typography
        variant="body2"
        color="textSecondary"
        sx={{
          mt: 1,
          fontFamily: 'Concert One, sans-serif',
          fontSize: '1rem',
        }}
      >
        {item.description}
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        sx={{
          mt: 1,
          fontFamily: 'Concert One, sans-serif',
          fontSize: '1rem',
        }}
      >
        Credits to unlock item: {item.price}
      </Typography>
      <Button
        variant="contained"
        sx={{
          mt: 3,
          backgroundColor: disabled ? '#bdbdbd' : '#39FF14',
          color: 'black',
          fontFamily: 'Concert One, sans-serif',
          fontSize: '1rem',
          '&:hover': {
            backgroundColor: disabled ? '#bdbdbd' : '#32CD32',
          },
          borderRadius: 3
        }}
        onClick={(e) => {
          e.stopPropagation();
          onBuy(item.id);
        }}
        disabled={disabled}
      >
        {disabled ? 'Owned' : 'Buy'}
      </Button>
      {disabled ? null : <Button
        variant="contained"
        sx={{
          mt: 3,
          ml: 3,
          backgroundColor: disabled ? '#bdbdbd' : '#39FF14',
          color: 'black',
          fontFamily: 'Concert One, sans-serif',
          fontSize: '1rem',
          '&:hover': {
            backgroundColor: disabled ? '#bdbdbd' : '#32CD32',
          },
          borderRadius: 3
        }}
        onClick={handleOpenSampleModal}
        disabled={disabled}
        
      >
        See Sample
      </Button>}
      <ShopItemSampleModal
        open={sampleModalOpen}
        handleClose={handleCloseSampleModal}
        collectionId={item.id}
      />
    </Paper>
  );
};

export default ShopItem;
