import React, { useEffect, useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import { Button, TextField, Box, Container, Typography, IconButton, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Lottie from 'lottie-react';
import loadingAnimation from '../assets/loading.json'; // Adjust the path as needed
import ForgotPasswordDialog from '../components/ForgotPasswordDialog'; // Import the new dialog component

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [openForgotPassword, setOpenForgotPassword] = useState(false);
  const { login, currentUser, resetPassword } = useAuth();
  const navigate = useNavigate();

  const handleLogin = async () => {
    setLoading(true);
    try {
      await login(email, password);
      navigate('/main');
    } catch (error) {
      alert(error.message);
    }
    setLoading(false);
  };

  const handleForgotPassword = async (email) => {
    setLoading(true);
    try {
      await resetPassword(email);
      alert('Password reset email sent');
      setOpenForgotPassword(false);
    } catch (error) {
      alert(error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (currentUser) {
      navigate('/main');
    }
  }, [currentUser, navigate]);

  return (
    <Container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        fontFamily: 'Concert One, sans-serif',
      }}
    >
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Lottie
            animationData={loadingAnimation}
            style={{ height: 300, width: 300 }}
          />
        </Box>
      ) : (
        <Paper
          sx={{
            padding: 4,
            maxWidth: 400,
            width: '100%',
            position: 'relative',
            textAlign: 'center',
            borderRadius: '20px',
            bgcolor: 'rgba(255, 255, 255, 0.9)',
          }}
        >
          <IconButton
            sx={{ position: 'absolute', top: 8, left: 8 }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h5" gutterBottom sx={{ fontFamily: 'Concert One, sans-serif' }}>
            Login
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={(e) => setEmail(e.target.value)}
              sx={{ borderRadius: '10px' }}
              disabled={loading}
            />
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={(e) => setPassword(e.target.value)}
              sx={{ borderRadius: '10px' }}
              disabled={loading}
            />
            <Button
              variant="contained"
              sx={{ mt: 2, borderRadius: '20px', fontFamily: 'Concert One, sans-serif' }}
              onClick={handleLogin}
              disabled={loading}
            >
              Login
            </Button>
            <Button
              variant="text"
              onClick={() => setOpenForgotPassword(true)}
              sx={{ mt: 2, fontFamily: 'Concert One, sans-serif' }}
              disabled={loading}
            >
              Forgot Password?
            </Button>
          </Box>
          <Typography variant="body2" sx={{ mt: 2, fontFamily: 'Concert One, sans-serif' }}>
            A new user?{' '}
            <Button
              variant="text"
              onClick={() => navigate('/signup')}
              sx={{ fontFamily: 'Concert One, sans-serif' }}
              disabled={loading}
            >
              Sign up here
            </Button>
          </Typography>
        </Paper>
      )}
      <ForgotPasswordDialog
        open={openForgotPassword}
        onClose={() => setOpenForgotPassword(false)}
        onSubmit={handleForgotPassword}
      />
    </Container>
  );
};

export default LoginPage;
