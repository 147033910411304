import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Paper, Button } from '@mui/material';
import { useCollections } from '../hooks/useCollections';

const ShopItemSampleModal = ({ open, handleClose, collectionId }) => {
  const [question, setQuestion] = useState(null);
  const { getFirstQuestionForCollection } = useCollections();

  useEffect(() => {
    const fetchFirstQuestion = async () => {
      const questionData = await getFirstQuestionForCollection(collectionId);
      setQuestion(questionData);
    };

    if (collectionId) {
      fetchFirstQuestion();
    }
  }, [collectionId, getFirstQuestionForCollection]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Paper
        sx={{
          p: 4,
          borderRadius: '20px',
          maxWidth: '600px',
          margin: 'auto',
          mt: '5%',
          outline: 'none'
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <Typography variant="h5" sx={{ mb: 2, fontFamily: 'Concert One, sans-serif' }}>Sample Question</Typography>
        {question ? (
          <Box>
            <Typography variant="h6" sx={{ fontFamily: 'Concert One, sans-serif' }}>Qns: {question.description}</Typography>
            {/* Additional fields can be displayed here */}
          </Box>
        ) : (
          <Typography>Loading...</Typography>
        )}
        <Button onClick={handleClose} sx={{ mt: 2, fontFamily: 'Concert One, sans-serif' }}>Close</Button>
      </Paper>
    </Modal>
  );
};

export default ShopItemSampleModal;
