import React from 'react';
import { Box, Typography, Paper} from '@mui/material';

const ShopItemComingSoon = () => {
  return (
    <Paper
      elevation={3}
      sx={{
        mb: 3,
        p: 3,
        borderRadius: '20px',
        backgroundColor: '#e0e0e0',
        fontFamily: 'Concert One, sans-serif',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography
          variant="h5"
          sx={{
            fontFamily: 'Concert One, sans-serif',
            fontSize: '1.5rem',
          }}
        >
          MORE COLLECTIONS COMING SOON
        </Typography>
      </Box>
      
    </Paper>
  );
};

export default ShopItemComingSoon;
