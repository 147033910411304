import React, { useContext, useState, useEffect, createContext } from 'react';
import { auth, db, storage } from '../firebase';
import { onAuthStateChanged, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, sendPasswordResetEmail, updateProfile } from 'firebase/auth';
import { doc, setDoc, getDoc, updateDoc, FieldValue } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const login = async (email, password) => {
    const result = await signInWithEmailAndPassword(auth, email, password);
    const user = result.user;
    const userData = await getUserData(user.email);
    setCurrentUser({ ...user, ...userData });
  };

  const updateLevel = async (newLevel) => {
    if (currentUser) {
      await updateDoc(doc(db, 'users', currentUser.email), { level: newLevel });
      setCurrentUser((prev) => ({ ...prev, level: newLevel }));
    }
  };

  const getCredits = async (email) => {
    const userDoc = await getDoc(doc(db, 'users', email));
    if (userDoc.exists()) {
      return userDoc.data().credits;
    } else {
      console.log('No such document!');
      return {};
    }
  };

  const resetPassword = (email) => {
    return sendPasswordResetEmail(auth, email);
  };

  const signup = async (email, password, name) => {
    const result = await createUserWithEmailAndPassword(auth, email, password);
    const user = result.user;
    await setDoc(doc(db, 'users', user.email), {
      email: user.email,
      name: name,
      age: 0,
      level: 'primary 6',
      rank: 0,
      skills: {
        'Math & Sciences': 0,
        'General Knowledge': 0,
        'Sports': 0,
        'Arts': 0,
        'Languages': 0,
      },
      photoUrl: "",
      collectionsOwned: {
        "Dt9JpncpiSs2nDJmkGsi": {
          "completeness": 0,
          "owned": true
        },
      },
      credits: 200,
      experience_points: 0
    });
    // Create an empty friendships document for the user
    await setDoc(doc(db, 'friendships', user.email), {
      friends: []
    });
    await updateProfile(user, { displayName: name });
    const userData = await getUserData(user.email); // Fetch user data after signup
    setCurrentUser({ ...user, ...userData });
  };

  const logout = () => {
    return signOut(auth);
  };

  const getSkills = async (email) => {
    const userDoc = await getDoc(doc(db, 'users', email));
    if (userDoc.exists()) {
      return userDoc.data().skills;
    } else {
      console.log('No such document!');
      return {};
    }
  };

  const getUserData = async (email) => {
    const userDoc = await getDoc(doc(db, 'users', email));
    if (userDoc.exists()) {
      const userData = userDoc.data();
      setCurrentUser((prev) => ({ ...prev, ...userData }));
      return userData;
    } else {
      console.log('No such document!');
      return {};
    }
  };

  const handleUploadProfilePic = async (file) => {
    if (!currentUser) return;
    const storageRef = ref(storage, `profilePics/${currentUser.email}`);
    await uploadBytes(storageRef, file);
    const photoURL = await getDownloadURL(storageRef);
    await updateProfile(auth.currentUser, { photoURL });
    await setDoc(doc(db, 'users', currentUser.email), { photoURL }, { merge: true });
    setCurrentUser({ ...currentUser, photoURL });
  };

  const updateExperiencePoints = (points) => {
    if (!currentUser) return;

    const userDoc = doc(db, 'users', currentUser.email);
    return updateDoc(userDoc, {
      experience_points: FieldValue.increment(points)
    }).then(() => {
      setCurrentUser((prev) => ({
        ...prev,
        experience_points: prev.experience_points + points
      }));
    });
  };

  const refreshUser = async () => {
    if (currentUser && currentUser.email) {
      const userData = await getUserData(currentUser.email);
      setCurrentUser((prev) => ({ ...prev, ...userData }));
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userData = await getUserData(user.email);
        setCurrentUser({ ...user, ...userData });
      } else {
        setCurrentUser(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    updateLevel,
    login,
    signup,
    logout,
    getSkills,
    getCredits,
    resetPassword,
    handleUploadProfilePic,
    updateExperiencePoints,
    getUserData,
    refreshUser
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
