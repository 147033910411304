import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Button, Avatar, Typography, MenuItem, Select, FormControl, InputLabel, LinearProgress } from '@mui/material';
import { useAuth } from '../hooks/useAuth';
import { getLevelFromXP, getXPForNextLevel, getXPForCurrentLevel } from '../utils/ExperiencePoints';

const schoolLevels = [
  'Primary 1', 'Primary 2', 'Primary 3', 'Primary 4', 'Primary 5', 'Primary 6',
  'Secondary 1', 'Secondary 2', 'Secondary 3', 'Secondary 4', 'Secondary 5', 'Junior College 1', 'Junior College 2', 'Others'
];

const ProfileHeader = () => {
  const { currentUser, handleUploadProfilePic, updateLevel, getCredits } = useAuth();
  const [schoolLevel, setSchoolLevel] = useState(currentUser?.level || '');
  const [gameLevel, setGameLevel] = useState(getLevelFromXP(currentUser?.experience_points || 0));
  const [expProgress, setExpProgress] = useState(0);
  const [creds, setCreds] = useState(0);

  const location = useLocation();
  const fetchCredits = async () => {
    if (currentUser) {
      const data = await getCredits(currentUser.email);
      setCreds(data);
    }
  };

  useEffect(() => {
    fetchCredits();
  }, [location]);

  useEffect(() => {
    const currentXP = currentUser?.experience_points || 0;
    const currentLevel = getLevelFromXP(currentXP);
    const previousLevelXP = getXPForCurrentLevel(currentLevel);
    const nextLevelXP = getXPForCurrentLevel(currentLevel + 1);
    const progress = ((currentXP - previousLevelXP) / (nextLevelXP - previousLevelXP)) * 100;

    setGameLevel(currentLevel);
    setExpProgress(progress);
  }, [currentUser]);

  const handleUpdateProfilePic = async (event) => {
    const file = event.target.files[0];
    if (file) {
      await handleUploadProfilePic(file);
      alert('Profile picture updated successfully!');
    }
  };

  const handleUpdateSchoolLevel = async (lvl) => {
    if (currentUser.level !== lvl) {
      await updateLevel(lvl);
    }
    setSchoolLevel(lvl);
  };

  const currentXP = currentUser?.experience_points || 0;
  const pointsToNextLevel = getXPForNextLevel(currentXP);

  return (
    <Box sx={{ minWidth: '45%', justifyContent: 'space-evenly', alignItems: 'center', p: 3, bgcolor: '#f5f5f5', borderRadius: '25px', minHeight: '80%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mr: 10 }}>
          <Avatar
            alt="User Avatar"
            src={currentUser?.photoURL || '/static/images/avatar/1.jpg'}
            sx={{ width: 100, height: 100, border: '5px solid black', mb: 3 }}
          />
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="upload-profile-pic"
            type="file"
            onChange={handleUpdateProfilePic}
          />
          <label htmlFor="upload-profile-pic">
            <Button variant="contained" component="span" sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '0.6rem' }}>
              Update Profile Picture
            </Button>
          </label>
        </Box>
        <Box>
          <Typography variant="h3" sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '2rem' }}>
            Name: {currentUser?.name}
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Typography variant="body1" sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '1rem' }}>
              Lvl: {gameLevel}
            </Typography>
            
            <LinearProgress
              variant="determinate"
              value={expProgress}
              sx={{
                height: 10,
                borderRadius: 5,
                backgroundColor: '#e0e0e0',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#00aaff',
                },
                mt: 1,
                mb: 1,
                borderWidth: 2,
                borderStyle: 'solid'
              }}
            />
            <Typography variant="body1" sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '0.8rem' }}>
              Experience Points: {currentXP}
            </Typography>
            <Typography variant="body1" sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '0.8rem' }}>
              Points to Next Level: {pointsToNextLevel}
            </Typography>
          </Box>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="school-level-select-label">School Level</InputLabel>
            <Select
              labelId="school-level-select-label"
              value={schoolLevel}
              label="School Level"
              onChange={(e) => handleUpdateSchoolLevel(e.target.value)}
              sx={{ borderRadius: '10px', fontFamily: 'Concert One, sans-serif' }}
            >
              {schoolLevels.map((lvl) => (
                <MenuItem key={lvl} value={lvl}>{lvl}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography variant="body1" sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '1rem' }}>
            Credits: {creds || currentUser?.credits}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileHeader;
