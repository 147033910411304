// CustomDialog.js
import React from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, Button, Typography } from '@mui/material';

const CustomDialog = ({ open, onClose, title, content, onConfirm, confirmText, cancelText }) => {
  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ sx: { borderRadius: '20px', padding: 3 } }}>
      <DialogTitle sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '1.5rem' }}>
        {title}
      </DialogTitle>
      <DialogContent>
        {content.map((text, index) => (
          <Typography key={index} variant="body1" sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '1rem' }}>
            {text}
          </Typography>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '1rem' }}>
          {cancelText}
        </Button>
        <Button onClick={onConfirm} color="error" sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '1rem' }}>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
