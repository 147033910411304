// utils/ExperiencePoints.js
const levels = [];
const baseXP = 50;

for (let level = 1; level <= 100; level++) {
  levels.push(baseXP * Math.pow(level, 2));
}

export const getLevelFromXP = (xp) => {
  let level = 1;

  for (let i = 0; i < levels.length; i++) {
    if (xp >= levels[i]) {
      level = i + 1;
    } else {
      break;
    }
  }

  return level;
};

export const getXPForNextLevel = (currentXP) => {
  const currentLevel = getLevelFromXP(currentXP);
  const nextLevelXP = levels[currentLevel] || levels[levels.length - 1];
  return nextLevelXP - currentXP;
};

export const getXPForCurrentLevel = (level) => {
  return levels[level - 1] || 0;
};
