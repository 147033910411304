import React from 'react';
import { Typography, Paper, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Box } from '@mui/material';

const QuestionCard = ({ question, onChange, selectedAnswer, showAnswer }) => {
  return (
    <Paper
      elevation={3}
      sx={{
        mb: 3,
        p: 3,
        borderRadius: '20px',
        backgroundColor: '#f9f6f1',
        borderColor: showAnswer
          ? selectedAnswer === question.answer
            ? 'green'
            : 'red'
          : 'transparent',
        borderWidth: '2px',
        borderStyle: 'solid',
        fontFamily: 'Concert One, sans-serif',
      }}
    >
      <Typography variant="h5" sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '1.5rem' }}>
        {question.description}
      </Typography>
      <FormControl component="fieldset" sx={{ mt: 2 }}>
        <FormLabel component="legend" sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '1.2rem' }}>
          Options
        </FormLabel>
        <RadioGroup
          name={`question-${question.id}`}
          value={selectedAnswer || ''}
          onChange={(e) => onChange(e.target.value)}
        >
          {Object.entries(question.options).map(([key, value], index) => (
            <Box key={key} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography sx={{ mr: 2 }}>{index + 1}.</Typography>
              <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '1rem' }}>
                  {value}
                </Typography>
                <FormControlLabel
                  value={key}
                  control={<Radio sx={{ ml: 1 }} />}
                  label=""
                  sx={{ m: 0 }}
                />
              </Box>
            </Box>
          ))}
        </RadioGroup>
      </FormControl>
    </Paper>
  );
};

export default QuestionCard;
