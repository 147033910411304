import { useContext, createContext, useState, useEffect } from 'react';
import { db } from '../firebase';
import { doc, getDoc, setDoc, updateDoc, arrayUnion, arrayRemove, query, collection, where, getDocs } from 'firebase/firestore';
import { useAuth } from './useAuth';

const FriendshipsContext = createContext();

export const useFriendships = () => {
  return useContext(FriendshipsContext);
};

export const FriendshipsProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const [friends, setFriends] = useState([]);
  const [friendRequests, setFriendRequests] = useState([]);

  useEffect(() => {
    if (currentUser) {
      fetchFriends();
      fetchFriendRequests();
    }
  }, [currentUser]);

  const fetchFriends = async () => {
    const docRef = doc(db, 'friendships', currentUser.email);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const friendsEmails = docSnap.data().friends || [];
      const friendsData = await Promise.all(friendsEmails.map(async email => {
        const userDoc = await getDoc(doc(db, 'users', email));
        return { email, ...userDoc.data() };
      }));
      // console.log(friendsData);
      setFriends(friendsData);
    }
  };

  const fetchFriendRequests = async () => {
    try {
      const q = query(collection(db, 'friend_requests'), where('receiver', '==', currentUser.email), where('status', '==', 'pending'));
      const querySnapshot = await getDocs(q);
  
      const requestsData = await Promise.all(querySnapshot.docs.map(async (requestDoc) => {
        const senderEmail = requestDoc.data().sender;
        const userDoc = await getDoc(doc(db, 'users', senderEmail));
  
        if (userDoc.exists()) {
          return { email: senderEmail, ...userDoc.data(), requestId: requestDoc.id };
        } else {
          return { email: senderEmail, requestId: requestDoc.id };
        }
      }));
  
      setFriendRequests(requestsData);
    } catch (error) {
      console.error('Error fetching friend requests:', error);
    }
  };
  

  const sendFriendRequest = async (receiverEmail) => {
    const requestRef = doc(collection(db, 'friend_requests'));
    await setDoc(requestRef, {
      sender: currentUser.email,
      receiver: receiverEmail,
      status: 'pending'
    });
  };

  const acceptFriendRequest = async (requestId, senderEmail) => {
    const docRef = doc(db, 'friendships', currentUser.email);
    await updateDoc(docRef, {
      friends: arrayUnion(senderEmail)
    });

    const senderDocRef = doc(db, 'friendships', senderEmail);
    await updateDoc(senderDocRef, {
      friends: arrayUnion(currentUser.email)
    });

    const requestRef = doc(db, 'friend_requests', requestId);
    await updateDoc(requestRef, {
      status: 'accepted'
    });

    fetchFriends();
    fetchFriendRequests();
  };

  const rejectFriendRequest = async (requestId) => {
    const requestRef = doc(db, 'friend_requests', requestId);
    await updateDoc(requestRef, {
      status: 'rejected'
    });

    fetchFriendRequests();
  };

  const value = {
    friends,
    friendRequests,
    sendFriendRequest,
    acceptFriendRequest,
    rejectFriendRequest,
  };

  return (
    <FriendshipsContext.Provider value={value}>
      {children}
    </FriendshipsContext.Provider>
  );
};
