import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Paper, Typography, IconButton, CircularProgress } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/system';
import CheckoutModal from '../components/CheckoutModal';
import useStore from '../hooks/useStore';
import { useAuth } from '../hooks/useAuth';

const CreditPack = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: 'center',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-10px)',
  },
  backgroundColor: '#f9f6f1', // lighter cream background
  fontFamily: 'Concert One, sans-serif',
  minHeight: 150,
  minWidth: 150,
  borderRadius: 20,
}));

const Container = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: '#f5efe6', // cream background
  textAlign: 'center',
  minHeight: 300,
  borderRadius: 20,
  paddingTop: theme.spacing(15)
}));

const BuyCredits = () => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const { storeItems, loading } = useStore();
  const { currentUser } = useAuth();
  const [currentPriceId, setCurrentPriceId] = useState('');
  const [currentCredits, setCurrentCredits] = useState('');
  const [uid, setUid] = useState('');

  const handleBuyCredits = async (priceId, credits) => {
    setCurrentPriceId(priceId);
    setCurrentCredits(credits);
    setUid(currentUser.email);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ maxWidth: 1000, mx: 'auto', mt: 8 }}>
      <IconButton onClick={() => navigate(-1)} sx={{ mb: 2 }}>
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h4" sx={{ textAlign: 'center', mb: 4, fontSize: '3rem', fontFamily: 'Concert One, sans-serif' }}>
        Purchase More Credits
      </Typography>
      <Container >
        <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
          {storeItems.filter((i) => (i.enabled)).sort((i,j) => {return i.price - j.price}).map((item) => (
            <CreditPack key={item.id}>
              <Typography variant="h5" sx={{fontFamily: 'Concert One, sans-serif'}}>{item.credits} Coins</Typography>
              <Typography variant="subtitle1" color="textSecondary" sx={{fontFamily: 'Concert One, sans-serif'}}>
                ${item.price.toFixed(2)}
              </Typography>
              <Button
                variant="contained"
                color="success"
                sx={{ mt: 2, fontFamily: 'Concert One, sans-serif' }}
                onClick={() => handleBuyCredits(item.item_id, item.credits)}
              >
                Buy
              </Button>
            </CreditPack>
          ))}
        </Box>
      </Container>
      <CheckoutModal open={modalOpen} handleClose={handleClose} priceId={currentPriceId} credits={currentCredits} userId={uid}/>
    </Box>
  );
};

export default BuyCredits;
