import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper } from '@mui/material';

// Helper function to process text for indents and line breaks
const processText = (text) => {
  // Replace escaped newlines with actual newline characters
  const lines = text.replace(/\\n/g, '\n').split('\n');

  return lines.map((line, index) => {
    // Count leading spaces for indentation
    const leadingSpaces = line.match(/^ */)[0].length;
    const indentLevel = leadingSpaces / 2; // Adjust as needed

    return (
      <Box key={index} sx={{ pl: `${indentLevel}rem` }}>
        {line.trim() ? line : <br />} {/* Handle empty lines */}
      </Box>
    );
  });
};

const AnswerCard = ({ answer, explanation }) => {
  const [explanationText, setExplanationText] = useState([]);

  useEffect(() => {
    if (explanation) {
      console.log("Explanation received:", explanation);
      setExplanationText(processText(String(explanation)));
    }
  }, [explanation]);

  return (
    <Paper
      elevation={3}
      sx={{
        p: 3,
        borderRadius: '20px',
        mt: 3,
        backgroundColor: '#f9f6f1',
        fontFamily: 'Concert One, sans-serif',
      }}
    >
      <Typography variant="body1" sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '1.2rem' }}>
        <strong>Answer:</strong> {answer}
      </Typography>
      <Typography variant="body2" sx={{ mt: 2, fontFamily: 'Concert One, sans-serif', fontSize: '1rem' }}>
        <strong>Explanation:</strong>
      </Typography>
      <Box sx={{ mt: 1 }}>
        {explanationText.map((line, index) => (
          <Typography
            key={index}
            variant="body2"
            sx={{ fontFamily: 'Concert One, sans-serif', fontSize: '1rem' }}
          >
            {line}
          </Typography>
        ))}
      </Box>
    </Paper>
  );
};

export default AnswerCard;
