import { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';

const useStore = () => {
  const [storeItems, setStoreItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStoreItems = async () => {
      const storeCollection = collection(db, 'store');
      const storeSnapshot = await getDocs(storeCollection);
      const storeList = storeSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setStoreItems(storeList);
      setLoading(false);
    };

    fetchStoreItems();
  }, []);

  return { storeItems, loading };
};

export default useStore;
