import React, { useState } from 'react';
import { Button, Box, Container, Typography, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import FeedbackModal from '../components/FeedbackModal'; // Import the FeedbackModal component
import './LandingPage.css'; // Make sure to have the CSS file for styling
import studyImage from '../assets/study.png'; // Adjust the path as needed
import aiImage from '../assets/ai.png'; // Example AI image
import progressImage from '../assets/progress.png'; // Example progress tracking image
import friendsImage from '../assets/friends.png'; // Example friends system image

const LandingPage = () => {
  const navigate = useNavigate();
  const [openFeedback, setOpenFeedback] = useState(false);

  const handleFeedbackOpen = () => {
    setOpenFeedback(true);
  };

  const handleFeedbackClose = () => {
    setOpenFeedback(false);
  };

  return (
    <Box sx={{ fontFamily: 'Concert One, sans-serif', backgroundColor: '#f5f5f5', minHeight: '100vh' }}>
      <Container maxWidth="lg" sx={{ py: 2 }}>
        {/* Hero Section */}
        <Grid container spacing={4} alignItems="center" sx={{ minHeight: '100vh' }}>
          <Grid item xs={12} md={6}>
            <Typography variant="h1" component="h1" sx={{ fontSize: '3.5rem', fontWeight: 'bold', color: 'black', fontFamily: 'Concert One, sans-serif', }}>
              Transform Your Learning Journey with Educat
            </Typography>
            <Typography variant="h6" component="p" sx={{ mt: 2, color: 'black', fontFamily: 'Concert One, sans-serif', }}>
              Experience a new way to prepare for exams with AI-generated questions based on past year papers. Track your progress, complete challenges, and level up as you master each topic. Join Educat and make studying engaging and effective!
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <img src={studyImage} alt="Study" style={{ width: '100%', height: 'auto' }} />
          </Grid>
        </Grid>

        {/* AI-generated Questions Section */}
        <Grid container spacing={4} alignItems="center" sx={{ mb: 8, minHeight: '50vh' }}>
          <Grid item xs={12} md={6}>
            <img src={aiImage} alt="AI Generated" style={{ width: '70%', height: 'auto' }} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h2" component="h2" sx={{ fontSize: '2.5rem', fontWeight: 'bold', color: 'black', fontFamily: 'Concert One, sans-serif' }}>
              AI-Generated Questions
            </Typography>
            <Typography variant="h6" component="p" sx={{ mt: 2, color: 'black', fontFamily: 'Concert One, sans-serif' }}>
              Our platform uses advanced AI to generate questions based on past year test papers, ensuring you get the most relevant and challenging practice.
            </Typography>
          </Grid>
        </Grid>

        {/* Progress Tracking Section */}
        <Grid container spacing={4} alignItems="center" sx={{ mb: 8 }}>
          <Grid item xs={12} md={6}>
            <Typography variant="h2" component="h2" sx={{ fontSize: '2.5rem', fontWeight: 'bold', color: 'black', fontFamily: 'Concert One, sans-serif', }}>
              Track Your Progress
            </Typography>
            <Typography variant="h6" component="p" sx={{ mt: 2, color: 'black', fontFamily: 'Concert One, sans-serif', }}>
              Monitor your progress over time with our intuitive tracking system. See improvements, and stay motivated.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <img src={progressImage} alt="Progress Tracking" style={{ width: '80%', height: 'auto' }} />
          </Grid>
        </Grid>

        {/* Friends System Section */}
        <Grid container spacing={4} alignItems="center" sx={{ mb: 8 }}>
          <Grid item xs={12} md={6}>
            <img src={friendsImage} alt="Friends System" style={{ width: '100%', height: 'auto' }} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h2" component="h2" sx={{ fontSize: '2.5rem', fontWeight: 'bold', color: 'black', fontFamily: 'Concert One, sans-serif', }}>
              Compete with Friends
            </Typography>
            <Typography variant="h6" component="p" sx={{ mt: 2, color: 'black', fontFamily: 'Concert One, sans-serif', }}>
              Make learning fun by competing with your friends. Track their progress, challenge each other, and improve together.
            </Typography>
          </Grid>
        </Grid>

        {/* Call to Action */}
        <Box sx={{ textAlign: 'center', mt: 8 }}>
          <Typography variant="h3" sx={{ mb: 4, color: 'black', fontFamily: 'Concert One, sans-serif', }}>
            Ready to Transform Your Learning Experience?
          </Typography>
          <Typography variant="h6" sx={{ mb: 4, color: 'black', fontFamily: 'Concert One, sans-serif', }}>
            Sign up now and get the first 200 credits free!
          </Typography>
        </Box>

        {/* Footer with Feedback Button */}
        <Box sx={{ textAlign: 'center', mt: 8, py: 4, borderTop: '1px solid #ccc' }}>
          <Button variant="text" onClick={handleFeedbackOpen} sx={{ fontFamily: 'Concert One, sans-serif' }}>
            Send Feedback
          </Button>
        </Box>
      </Container>

      {/* Floating Button Container */}
      <Box sx={{ position: 'fixed', bottom: '10%', left: '50%', transform: 'translateX(-50%)', width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ display: 'flex', gap: 2, borderRadius: '30px', p: 2, bgcolor: 'rgba(20, 25, 25, 0.1)' }}>
          <Button variant="contained" sx={{ borderRadius: '15px', minWidth: '150px', bgcolor: 'rgba(45,162,176,1.0)', fontFamily: 'Concert One, sans-serif', }} onClick={() => navigate('/login')}>
            Login
          </Button>
          <Button variant="contained" sx={{ borderRadius: '15px', minWidth: '150px', bgcolor: 'rgba(254,181,70,1.0)', fontFamily: 'Concert One, sans-serif', }} onClick={() => navigate('/signup')}>
            Sign Up
          </Button>
        </Box>
      </Box>

      <FeedbackModal open={openFeedback} onClose={handleFeedbackClose} />
    </Box>
  );
};

export default LandingPage;
