import { useContext, createContext, useState, useEffect } from 'react';
import { db } from '../firebase';
import { doc, getDoc, getDocs, updateDoc, collection, increment } from 'firebase/firestore';
import { useAuth } from './useAuth';

const CollectionsContext = createContext();

export const useCollections = () => {
  return useContext(CollectionsContext);
};

export const CollectionsProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const [collections, setCollections] = useState([]);
  const [userCollections, setUserCollections] = useState([]);
  const [questions, setQuestions] = useState([]);

  const getCollectionsByUserEmail = async (email) => {
    try {
      const userDoc = await getDoc(doc(db, 'users', email));
      if (userDoc.exists()) {
        const userCollectionsData = userDoc.data().collectionsOwned || {};
        const collectionIds = Object.keys(userCollectionsData);

        if (collectionIds.length > 0) {
          const collectionsList = await Promise.all(
            collectionIds.map(async (collectionId) => {
              const collectionDoc = await getDoc(doc(db, 'question_collections', collectionId));
              if (collectionDoc.exists()) {
                return {
                  id: collectionDoc.id,
                  ...collectionDoc.data(),
                  completeness: userCollectionsData[collectionDoc.id].completeness,
                  latest_score: userCollectionsData[collectionDoc.id].latest_score,
                  highest_score: userCollectionsData[collectionDoc.id].highest_score,
                };
              } else {
                return null;
              }
            })
          );

          setUserCollections(collectionsList.filter(collection => collection !== null));
        } else {
          setUserCollections([]);
        }
      } else {
        console.log('No user document found');
        setUserCollections([]);
      }
    } catch (error) {
      console.error('Error getting user collections:', error);
      setUserCollections([]);
    }
  };

  const getAllCollections = async () => {
    try {
      const collectionsSnapshot = await getDocs(collection(db, 'question_collections'));
      const collectionsList = collectionsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCollections(collectionsList);
    } catch (error) {
      console.error('Error getting all collections:', error);
    }
  };

  const getQuestionsForCollection = async (collectionId) => {
    try {
      const collectionDoc = await getDoc(doc(db, 'question_collections', collectionId));
      if (collectionDoc.exists()) {
        const questionIds = collectionDoc.data().questions;
        const questionsList = await Promise.all(
          questionIds.map(async (questionId) => {
            const questionDoc = await getDoc(doc(db, 'questions', questionId));
            if (questionDoc.exists()) {
              return {
                id: questionDoc.id,
                ...questionDoc.data(),
              };
            } else {
              return null;
            }
          })
        );

        setQuestions(questionsList.filter(question => question !== null));
      } else {
        console.log('No collection document found');
        setQuestions([]);
      }
    } catch (error) {
      console.error('Error getting questions for collection:', error);
      setQuestions([]);
    }
  };

  const getFirstQuestionForCollection = async (collectionId) => {
    try {
      const collectionDoc = await getDoc(doc(db, 'question_collections', collectionId));
      if (collectionDoc.exists()) {
        const questionId = collectionDoc.data().questions[0];
        const questionDoc = await getDoc(doc(db, 'questions', questionId));
        if (questionDoc.exists()) {
          return {
            id: questionDoc.id,
            ...questionDoc.data(),
          };
        }
      }
      return null;
    } catch (error) {
      console.error('Error getting the first question for collection:', error);
      return null;
    }
  };

  const updateCollectionCompleteness = async (collectionId, email, completeness, countCorrect, latestScore, experiencePoints) => {
    try {
      const userDocRef = doc(db, 'users', email);
      const userDoc = await getDoc(userDocRef);
  
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const collectionsOwned = userData.collectionsOwned || {};
  
        if (collectionsOwned[collectionId]) {
          let prevScore = collectionsOwned[collectionId].latest_score;
          collectionsOwned[collectionId].completeness = 100;
          collectionsOwned[collectionId].latest_score = latestScore;
          const prevCount = prevScore.split('/')[0];
          const latestCount = latestScore.split('/')[0];
          const denom = latestScore.split('/')[1];

          if (latestCount > parseInt(prevCount, 10) || !collectionsOwned[collectionId].highest_score) {
            // Your code here
            let highScore = `${latestCount}/${denom}`
            collectionsOwned[collectionId].highest_score = highScore;
          }
        } else {
          collectionsOwned[collectionId] = {
            completeness: completeness,
            owned: true,
            latest_score: latestScore,
            highest_score: latestScore,
          };
        }
  
        await updateDoc(userDocRef, {
          collectionsOwned,
          experience_points: increment(experiencePoints),
        });
  
        if (currentUser && currentUser.email === email) {
          await getCollectionsByUserEmail(email);
        }
      }
    } catch (error) {
      console.error('Error updating completeness and experience points:', error);
    }
  };

  const isCollectionOwned = (collectionId) => {
    return userCollections.some(collection => collection.id === collectionId);
  };

  useEffect(() => {
    if (currentUser) {
      getCollectionsByUserEmail(currentUser.email);
    }
    getAllCollections();
  }, [currentUser]);

  const value = {
    collections,
    userCollections,
    questions,
    getCollectionsByUserEmail,
    getAllCollections,
    getQuestionsForCollection,
    updateCollectionCompleteness,
    isCollectionOwned,
    getFirstQuestionForCollection
  };

  return (
    <CollectionsContext.Provider value={value}>
      {children}
    </CollectionsContext.Provider>
  );
};
